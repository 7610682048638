import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const EventsPage = () => {
    return (
        <Layout>
            <SEO title="Our Events | Digital Madness" />
        </Layout>
    )
}

export default EventsPage